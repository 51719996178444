/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useForm } from "react-hook-form";
import FileSaver from "file-saver";
import { Button, Row, Col } from "react-bootstrap";
import { FaRegChartBar } from "react-icons/fa";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import { Backr, Form } from "./styles";

export default function LogsReport(role) {
  const {
    register: logDates,
    handleSubmit: handleLogRequest,
    setValue: setLogValues,
  } = useForm();
  const endDateDefault = new Date();
  const beginDateDefault = new Date();
  beginDateDefault.setMonth(beginDateDefault.getMonth() - 1);
  const endDate = endDateDefault.toISOString().substring(0, 10);
  const beginDate = beginDateDefault.toISOString().substring(0, 10);

  setLogValues("beginDate", beginDate);
  setLogValues("endDate", endDate);

  const onGetLogs = (data) => {
    async function requestLogs() {
      const requestingReport = await api
        .post("/api/system_logs", data, {
          responseType: "blob",
        })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const today = new Date();
          FileSaver.saveAs(
            blob,
            `relatorio_Logs-${today.getDate()}-${
              today.getMonth() + 1
            }-${today.getFullYear()}.xlsx`
          );
          toast.success("✔ Relatorio de Logs sendo gerado");
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
      return requestingReport;
    }
    requestLogs();
  };

  if (role === "MASTER") {
    return (
      <Backr>
        <h1>
          <FaRegChartBar color="#333" />
          Logs - Relatório
        </h1>
        <Form onSubmit={handleLogRequest(onGetLogs)}>
          <Col>
            <Row>
              <label>Data Inicio:</label>
            </Row>
            <Row>
              <input
                type="date"
                name="beginDate"
                id="beginDate"
                label="beginDate"
                style={{ width: "70%" }}
                defaultValue={beginDate}
                {...logDates("beginDate", { required: true })}
              />
            </Row>
          </Col>

          <Col>
            <Row>
              <label>Data Fim:</label>
            </Row>
            <Row>
              <input
                type="date"
                name="endDate"
                id="endDate"
                label="endDate"
                style={{ width: "70%" }}
                defaultValue={endDate}
                {...logDates("endDate", { required: true })}
              />
            </Row>
          </Col>

          <Button type="submit">Ver Logs</Button>
        </Form>
      </Backr>
    );
  }
  return <></>;
}
