/* eslint-disable import/no-unresolved */
/* eslint-disable react/react-in-jsx-scope */
import { FaRegFileExcel, FaRegChartBar } from "react-icons/fa";
import { Button, Row, Col, Container } from "react-bootstrap";

import FileSaver from "file-saver";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import { Backr } from "./styles";

export default function UsageReport(role) {
  // Função para gerar relatorio de uso da plataforma
  const getReport = (e) => {
    e.preventDefault();
    async function requestReport() {
      const requestingReport = await api
        .get("/api/report", {
          responseType: "blob",
        })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const today = new Date();
          FileSaver.saveAs(
            blob,
            `report-${today.getDate()}-${
              today.getMonth() + 1
            }-${today.getFullYear()}.xlsx`
          );
        });
      return requestingReport;
    }
    requestReport();
    toast.success("✔ Relatorio sendo gerado");
  };

  if (role === "MASTER" || role === "CS") {
    return (
      <Backr>
        <h1>
          <FaRegChartBar color="#333" />
          Relatório de Acesso
        </h1>
        <Container className="mt-4">
          <Row className="justify-content-center">
            <Col xs="auto">
              <Button onClick={getReport}>
                <FaRegFileExcel /> Gerar Relatório
              </Button>
            </Col>
          </Row>
        </Container>
      </Backr>
    );
  }
  return <></>;
}
