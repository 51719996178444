/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-console */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FaDollarSign, FaCheck, FaTimes, FaTrash } from "react-icons/fa";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Container from "react-bootstrap/Container";
import { toast } from "react-toastify";
import {
  Row,
  Col,
  Modal,
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  Form,
} from "react-bootstrap";
import { Backr, Table, TableCell } from "./styles";
import api from "../../services/api";

export default function RequestContractManagment(role) {
  const [contractRequest, setContractRequest] = useState([]);
  const [contractInfoNewContract, setContractInfoNewContract] = useState([]);
  const [contractInfoCancelContract, setContractInfoCancelContract] = useState(
    []
  );
  const [showNewContract, setShowNewContract] = useState(false);
  const [showCancelContract, setShowCancelContract] = useState(false);
  const handleCloseNewContract = () => setShowNewContract(false);
  const handleCloseCancelContract = () => setShowCancelContract(false);

  const {
    register: registerContractPrice,
    handleSubmit: handleSubmitContractPrice,
    setValue,
    getValues,
  } = useForm();

  const {
    register: registerCancelContract,
    handleSubmit: handleSubmitCancelContract,
    setValue: setValueCancelContract,
    getValues: getValueCancelContract,
  } = useForm();

  async function getNewContractRequests() {
    const response = await api.get("/api/show_contract_requests");
    setContractRequest(response.data);
  }

  useEffect(() => {
    getNewContractRequests();
  }, []);

  function getContractInfos(contractId) {
    const infos = contractRequest.filter(
      (contract) =>
        // eslint-disable-next-line no-underscore-dangle
        contract._id.$oid === contractId
    );
    return infos[0];
  }

  async function createContract(contractId) {
    api
      .post("/api/accept_new_contract_request", { objectId: contractId })
      .then((response) => {
        toast.success(response.data.message);
        getNewContractRequests();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }

  const onSubmitCancelContract = async (data) => {
    async function request() {
      const send = await api
        .post("/api/deny_contract_generation", data)
        .then((response) => {
          toast.success(response.data.message);
          getNewContractRequests();
        })
        .catch((error) => {
          // eslint-disable-next-line no-unused-expressions
          error.response
            ? toast.error(error.response.data.message)
            : toast.error(JSON.stringify(error));
        });
      return send;
    }
    request();
    setShowCancelContract(false);
  };

  const updateContractPrice = (data) => {
    const thisContract = getContractInfos(data);
    setContractInfoNewContract(thisContract);
    setValue("objectId", data);
    setShowNewContract(true);
    setTimeout(() => {
      document.getElementById("creditsAmount").value = thisContract.credits;
      document.getElementById("valuePerCredit").value =
        thisContract.price_per_credit;
      document.getElementById("activationFee").value =
        thisContract.activation_fee;
      console.log(thisContract);
      setValue("price_per_credit", thisContract.price_per_credit);
      setValue("credits", thisContract.credits);
      setValue("activation_fee", thisContract.activation_fee);
    }, 350);
  };

  const cancelContractModal = (data) => {
    const thisContract = getContractInfos(data);
    setContractInfoCancelContract(thisContract);
    setShowCancelContract(true);
    setValueCancelContract("objectId", data);
  };

  function tableRequests() {
    if (contractRequest.length !== 0) {
      return (
        <Table>
          <tr>
            <th> Clinica</th>
            <th> Responsavel</th>
            <th> Dados</th>
          </tr>
          {contractRequest.map((request) => (
            <tr style={{ marginTop: "5px", marginBottom: "50px" }}>
              <td style={{ marginTop: "5px" }}>
                <TableCell>
                  <div>
                    <span>{request.clinic_name} </span>
                  </div>
                  <div>
                    <span>{request.cnpj}</span>
                  </div>
                  <div>
                    <span>
                      {request.city} - {request.uf}
                    </span>
                  </div>
                  <div>
                    <span>É teste:{request.is_test ? "SIM" : "NÃO"}</span>
                  </div>
                </TableCell>
              </td>
              <td>
                <div>
                  <div>
                    <span>{request.customer_name}</span>
                  </div>
                  <div>
                    <span>{request.customer_email}</span>
                  </div>
                  <div>
                    <span>{request.phone_number}</span>
                  </div>
                </div>
              </td>
              <td>
                <div
                  onClick={() => {
                    // eslint-disable-next-line no-underscore-dangle
                    updateContractPrice(request._id.$oid);
                  }}
                  style={{
                    textDecoration: "inherit",
                    color: "inherit",
                    cursor: "pointer",
                  }}
                >
                  <div>
                    <span>Créditos: {request.credits}</span>
                  </div>
                  <div>
                    <span>Plano: {request.plan_type}</span>
                  </div>
                  <div>
                    <span>
                      R${" "}
                      {(request.price_per_credit * request.credits).toFixed(2)}
                    </span>
                  </div>
                </div>
              </td>
              <td>
                <Row
                  className="justify-content-around"
                  style={{ margin: "0px" }}
                >
                  <Col nopadding style={{ padding: "0px", cursor: "pointer" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <FaCheck
                        onClick={() => {
                          // eslint-disable-next-line no-underscore-dangle
                          createContract(request._id.$oid);
                        }}
                        color="green"
                      />
                    </div>
                  </Col>

                  <Col nopadding style={{ padding: "0px", cursor: "pointer" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <FaTrash
                        onClick={() => {
                          // eslint-disable-next-line no-underscore-dangle
                          cancelContractModal(request._id.$oid);
                        }}
                        color="red"
                      />
                    </div>
                  </Col>
                </Row>
              </td>
            </tr>
          ))}
        </Table>
      );
    }
    return (
      <Row className="justify-content-center">
        <Col>
          <p>Não há nenhuma nova solicitação de contrato</p>
        </Col>
      </Row>
    );
  }

  const onSubmitContractUpdate = async (data) => {
    async function request() {
      const send = await api
        .put(`/api/update_contract_price_and_credits`, data)
        .then((response) => {
          getNewContractRequests();
          toast.success(response.data.message);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
      return send;
    }
    request();
    setShowNewContract(false);
  };

  if (role === "MASTER" || role === "SALES") {
    return (
      <>
        <Backr>
          <h1>
            <FaDollarSign color="#333" />
            Solicitação de contrato
          </h1>
          <div>{tableRequests()}</div>
        </Backr>

        <Modal show={showNewContract} onHide={handleCloseNewContract}>
          <Modal.Body className="show-grid">
            <Container>
              <Form>
                <Row>
                  <Col xs={7}>
                    <FormGroup className="mb-1">
                      <FormLabel>Id do contrato </FormLabel>
                      <FormControl value={getValues().objectId} disabled />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="mb-1">
                      <FormLabel>CNPJ</FormLabel>
                      <FormControl
                        value={contractInfoNewContract.cnpj}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <FormGroup className="mb-1">
                    <FormLabel>Nome da Clinica</FormLabel>
                    <FormControl
                      value={contractInfoNewContract.clinic_name}
                      disabled
                    />
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup className="mb-1">
                    <FormLabel>Email</FormLabel>
                    <FormControl
                      value={contractInfoNewContract.customer_email}
                      disabled
                    />
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup className="mb-1">
                    <FormLabel>Nome</FormLabel>
                    <FormControl
                      value={contractInfoNewContract.customer_name}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup className="mb-1">
                    <FormLabel>Telefone</FormLabel>
                    <FormControl
                      value={contractInfoNewContract.phone_number}
                      disabled
                    />
                  </FormGroup>
                </Row>
              </Form>

              <Form>
                <Row>
                  <Col xs={3}>
                    <FormGroup>
                      <FormLabel>R$/Crédito:</FormLabel>
                      <FormControl
                        required
                        id="valuePerCredit"
                        placeholder={
                          contractInfoCancelContract.price_per_credit
                        }
                        type="number"
                        step="0.01"
                        {...registerContractPrice("price_per_credit")}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={3}>
                    <FormGroup>
                      <FormLabel>Quantidade</FormLabel>
                      <FormControl
                        required
                        id="creditsAmount"
                        placeholder={contractInfoCancelContract.credits}
                        type="number"
                        {...registerContractPrice("credits")}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={4}>
                    <FormGroup>
                      <FormLabel>Ativação</FormLabel>
                      <FormControl
                        required
                        id="activationFee"
                        placeholder={contractInfoCancelContract.activation_fee}
                        step="0.01"
                        type="number"
                        {...registerContractPrice("activation_fee")}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-danger" onClick={handleCloseNewContract}>
              <FaTimes /> Cancelar
            </Button>
            <Button
              variant="success"
              onClick={handleSubmitContractPrice(onSubmitContractUpdate)}
            >
              <FaCheck /> Confirmar
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="lg"
          show={showCancelContract}
          onHide={handleCloseCancelContract}
        >
          <Modal.Body className="show-grid">
            <Form>
              <Row>
                <Col xs={7}>
                  <FormGroup className="mb-1">
                    <FormLabel>Id do contrato </FormLabel>
                    <FormControl
                      value={getValueCancelContract().objectId}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mb-1">
                    <FormLabel>CNPJ</FormLabel>
                    <FormControl
                      value={contractInfoCancelContract.cnpj}
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <FormGroup className="mb-1">
                  <FormLabel>Nome da Clinica</FormLabel>
                  <FormControl
                    value={contractInfoCancelContract.clinic_name}
                    disabled
                  />
                </FormGroup>
              </Row>
              <Row>
                <FormGroup className="mb-1">
                  <FormLabel>Email</FormLabel>
                  <FormControl
                    value={contractInfoCancelContract.customer_email}
                    disabled
                  />
                </FormGroup>
              </Row>
              <Row>
                <FormGroup className="mb-1">
                  <FormLabel>Nome</FormLabel>
                  <FormControl
                    value={contractInfoCancelContract.customer_name}
                    disabled
                  />
                </FormGroup>
                <FormGroup className="mb-1">
                  <FormLabel>Telefone</FormLabel>
                  <FormControl
                    value={contractInfoCancelContract.phone_number}
                    disabled
                  />
                </FormGroup>
              </Row>
            </Form>

            <Form>
              <Row>
                <Col>
                  <FormGroup>
                    <FormLabel>Observação</FormLabel>
                    <FormControl
                      required
                      id="observation"
                      value="Por algum motivo técnico sua proposta não foi realizada. Favor entrar em contato com nosso time comercial através da nossa Central de Atendimento, Whatsapp +55 62 8182-6772."
                      type="text"
                      as="textarea"
                      rows="3"
                      {...registerCancelContract("observation")}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <FormGroup>
                    <Form.Check
                      type="checkbox"
                      id="send_mail"
                      label="Enviar Email"
                      {...registerCancelContract("send_mail")}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-danger"
              onClick={handleCloseCancelContract}
            >
              <FaTimes /> Fechar janela
            </Button>
            <Button
              variant="success"
              onClick={handleSubmitCancelContract(onSubmitCancelContract)}
            >
              <FaCheck /> Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  return <></>;
}
