/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-console */
/* eslint-disable react/react-in-jsx-scope */
import { Row, Col } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Backr, Table } from "./styles";
// import acceptDataBaseCreation from "../../services/acceptDataBaseCreation";
import api from "../../../../services/api";

function table() {
  const [tokens, setTokens] = useState([]);

  async function loadTokens() {
    const response = await api.get("/api/list_opened_contracts");
    setTokens(response.data);
  }
  useEffect(() => {
    loadTokens();
  }, []);

  async function acceptDataBaseCreation(token, cgc, company, companyId) {
    api
      .post("/api/activate_base", {
        cgc,
        company,
        token,
        companyId,
      })
      .then((response) => {
        toast.success(response.data.message);
        loadTokens();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }

  if (Array.isArray(tokens) && tokens.length) {
    return (
      <Table>
        <tr>
          <th>Clinica</th>
          <th>CPF/CPNJ</th>
          <th>Usuario</th>
        </tr>
        {tokens.map((tokenData) => (
          <tr style={{ marginTop: "5px", marginBottom: "50px" }}>
            <td style={{ marginTop: "5px" }}>
              <div>
                <span>{tokenData.nome_empresa} </span>
                <span>{tokenData.is_test}</span>
              </div>
            </td>
            <td>
              <div>
                <span>{tokenData.cgc}</span>
              </div>
            </td>
            <td>
              <div>
                <span>Usuario: {tokenData.id}</span>
              </div>
            </td>
            <td>
              <Row className="justify-content-around" style={{ margin: "0px" }}>
                <Col nopadding style={{ padding: "0px", cursor: "pointer" }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <FaCheck
                      onClick={() => {
                        acceptDataBaseCreation(
                          tokenData.token,
                          tokenData.cgc,
                          tokenData.nome_empresa,
                          tokenData.cod_empresa
                        );
                      }}
                      color="green"
                    />
                  </div>
                </Col>
              </Row>
            </td>
          </tr>
        ))}
      </Table>
    );
  }
  return <p>Não há tokens em aberto para liberação de base</p>;
}

export default function BaseCreationToken(role) {
  if (role === "MASTER") {
    return (
      <Backr>
        <h1>Tokens liberação de base</h1>
        {table()}
      </Backr>
    );
  }
  return <></>;
}
