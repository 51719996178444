/* eslint-disable react/react-in-jsx-scope */

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Row, Col, Button, FormControl, Form } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { Backr } from "./styles";
import api from "../../../../services/api";

export default function UpdateAdminUserPassword(role) {
  const {
    register: registerAdminPasswordReset,
    handleSubmit: handleSubmitAdminPasswordReset,
  } = useForm();

  const onSubmitAdminPasswordReset = (data) => {
    async function request() {
      const send = await api
        .put(`/api/createuser`, data)
        .then(() => {
          toast.success(`Usuario atualizado!`);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
      return send;
    }
    request();
  };

  if (role === "MASTER") {
    return (
      <Backr>
        <h1>Atualizar Senha Usuario Administrativo</h1>
        <Form
          onSubmit={handleSubmitAdminPasswordReset(onSubmitAdminPasswordReset)}
        >
          <Row>
            <Col cs={9}>
              <FormControl
                required
                id="email"
                type="input"
                {...registerAdminPasswordReset("email", { required: true })}
              />
            </Col>
          </Row>
          <Row className="mt-2 mb-2">
            <Col cs={9}>
              <FormControl
                required
                id="password"
                type="password"
                {...registerAdminPasswordReset("password", {
                  required: true,
                })}
              />
            </Col>
          </Row>
          <Col>
            <Button type="submit">
              <FaCheck /> Atualizar senha
            </Button>
          </Col>
        </Form>
      </Backr>
    );
  }
  return <> </>;
}
